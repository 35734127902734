import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout/Layout";
import { HTMLContent } from "../components/common/Content";

// props
interface Props {
  data: {
    page: {
      html: any;
      frontmatter: {
        title: string;
        description?: string;
        keywords?: Array<string>;
        slug: string;
      };
    };
  };
}

// markup
const HomeTemplate = (props: Props) => {
  return (
    <Layout className={`page home`}>
      <section className="container">
        <div className="content markdown py-6">
          <div className="py-6 my-6 has-text-centered">
            <h1 className="m-0 py-0 is-size-2">
              Create Efficient Reactive Systems
            </h1>
            <p className="m-0">
              Reactor is a fourth-generation reactive library, based on the
              Reactive Streams specification, for building non-blocking
              applications on the JVM
            </p>
          </div>
        </div>
      </section>
      <section className="has-background-light">
        <div className="container">
          <div className="content markdown">
            <div className="columns mt-0 mb-0 py-6">
              <article className="column has-text-centered">
                <div className="is-icon">
                  <img src="/img/home/ico-1.svg" className="img-light" />
                  <img src="/img/home/ico-1-dark.svg" className="img-dark" />
                </div>
                <h1 className="py-1">Reactive Core</h1>
                <p>
                  Reactor is <strong>fully non-blocking</strong> and provides
                  efficient demand management. It directly interacts with Java's
                  functional API, <code>CompletableFuture</code>,{" "}
                  <code>Stream</code>, and <code>Duration</code>.
                </p>
              </article>
              <article className="column has-text-centered px-4">
                <div className="is-icon">
                  <img src="/img/home/ico-2.svg" className="img-light" />
                  <img src="/img/home/ico-2-dark.svg" className="img-dark" />
                </div>
                <h1 className="py-1">Typed [0|1|N] Sequences</h1>
                <p>
                  Reactor offers{" "}
                  <strong>two reactive and composable APIs</strong>,{" "}
                  <a href="/docs/core/release/api/reactor/core/publisher/Flux.html">
                    Flux [N]
                  </a>{" "}
                  and{" "}
                  <a href="/docs/core/release/api/reactor/core/publisher/Mono.html">
                    Mono [0|1]
                  </a>
                  , which extensively implement{" "}
                  <a href="http://reactivex.io">Reactive Extensions</a>.
                </p>
              </article>
              <article className="column has-text-centered">
                <div className="pl-4">
                  <div className="is-icon">
                    <img src="/img/home/ico-3.svg" className="img-light" />
                    <img src="/img/home/ico-3-dark.svg" className="img-dark" />
                  </div>
                  <h1 className="py-1">Non-Blocking IO</h1>
                  <p>
                    Well-suited for a <strong>microservices</strong>
                    {` `}
                    architecture, Reactor offers{` `}
                    <strong>backpressure-ready network engines</strong> for HTTP
                    (including Websockets), TCP, and UDP.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="content markdown pt-4">
          <article className="columns py-6">
            <div className="column ml-6 is-4 pr-6">
              <h1 className="is-size-3 is-size-3-mobile has-text-weight-medium m-0 p-0">
                Efficient Message Passing
              </h1>
            </div>
            <div className="column is-6">
              <div className="">
                Reactor operators and schedulers can{" "}
                <strong>sustain high throughput rates</strong>, on the order of
                10's of millions of messages per second. Its{" "}
                <b>low memory footprint</b> goes under most radars. Reactor Core
                is the first reactive library based on the joint reactive{" "}
                <a href="https://github.com/reactor/reactive-streams-commons">
                  research effort
                </a>{" "}
                also implemented by <a href="http://reactivex.io">RxJava 2</a>.
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column ml-6 is-4 pr-6">
              <h1 className="is-size-3 is-size-3-mobile has-text-weight-medium m-0 p-0">
                A Micro Reactive Toolkit for All
              </h1>
            </div>
            <div className="column is-6">
              <div className="">
                <p>
                  Don't write Reactive Streams yourself! Reactor's modules are{" "}
                  <strong>embeddable and interoperable</strong>. They focus on
                  providing <strong>rich and functional</strong>
                  {` `}
                  <a href="https://www.reactive-streams.org/">
                    Reactive Streams
                  </a>{" "}
                  APIs. You can use Reactor at any level of granularity:
                </p>
                <ul>
                  <li>
                    in frameworks such as <a href="https://spring.io">Spring</a>{" "}
                    Boot and WebFlux
                  </li>
                  <li>
                    in drivers and clients such as the{" "}
                    <a href="https://github.com/cloudfoundry/cf-java-client">
                      CloudFoundry Java Client
                    </a>
                  </li>
                  <li>
                    in contracts or protocols such as{" "}
                    <a href="http://rsocket.io">RSocket</a> and{" "}
                    <a href="https://r2dbc.io">R2DBC</a>
                  </li>
                </ul>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column ml-6 is-4 pr-6">
              <h1 className="is-size-3 is-size-3-mobile has-text-weight-medium m-0 p-0">
                Guardians of the Latency
              </h1>
            </div>
            <div className="column is-6">
              <div className="">
                Scaling out is an important tool for overcoming latency and slow
                microservices. Cloud native apps and serverless functions have a
                better chance at effective scale-out with asynchronous
                architectures. To assist in asynchronous designs, Reactor offers
                non-blocking and backpressure-ready network runtimes, including
                local TCP/HTTP/UDP clients and servers, based on the robust{" "}
                <a href="https://netty.io/">Netty</a> framework.
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default HomeTemplate;

// graphQL Index page queries
export const pageQuery = graphql`
  query HomeTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        slug
      }
    }
  }
`;
